import React, { useEffect, useState, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { TextField, Button, Box, Typography, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SnackbarUI from '../common/snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import {api} from "../../utils/api";

const roles = [
  { label: "Super Admin", value: "SUPER_ADMIN" },
  { label: "Admin", value: "ADMIN" },
  { label: "User", value: "USER" },
];

const formFields = [
  { label: "Name", name: "name" },
  { label: "Email", name: "email" },
  { label: "Password", name: "password", type: "password" },
];

const AdminSignup = () => {

  const [open, setOpenSnack] = useState(false);
  const [content, setContent] = useState('');
  const [severity,setSeverity] = useState('success')

  const navigate = useNavigate();

  const LoginSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    role: Yup.string().required("Role is required"),
  });

  const handleSignUp = async (values, { setSubmitting, setErrors }) => {
    console.log("SignUp Data:", values);
    try {
      const response = await api.post("/users/sign-up", values);
      if (response.status === 201 || response.status === 200) {
        navigate("/admin/login");
      }

    } catch (error) {
      setOpenSnack(true)
      setContent(error.message)
      setSeverity("error")
      setErrors({ email: "Invalid email or password" });
    }
    
    setSubmitting(false);
  };

  return (
    <>
    <Formik
      initialValues={{ name: "", email: "", password: "", role: "SUPER_ADMIN" }}
      validationSchema={LoginSchema}
      onSubmit={handleSignUp}
    >
      {({ values, errors, touched, isSubmitting, handleChange }) => (
        <Form>
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            maxWidth={400}
            mx="auto"
            p={3}
            boxShadow={3}
            borderRadius={2}
          >
            <Typography variant="h5" textAlign="center">
              Create User
            </Typography>

            {formFields.map((field) => (
              <>
              <label>{field.label}</label>
              <Field
                key={field.name}
                as={TextField}
                name={field.name}
                type={field.type || "text"}
                value={values[field.name]}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                error={touched[field.name] && !!errors[field.name]}
                helperText={touched[field.name] && errors[field.name]}
                InputProps={{ style: { color: "white" } }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": { borderColor: "#C6A566" },
                    "&:hover fieldset": { borderColor: "#C6A566" },
                    "&.Mui-focused fieldset": { borderColor: "#C6A566" },
                  },
                  "& .MuiInputLabel-root": { color: "#C6A566" },
                }}
              />
              </>
            ))}

            <label>Role</label>
            <Field
              as={TextField}
              select
              name="role"
              value={values.role}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              error={touched.role && !!errors.role}
              helperText={<ErrorMessage name="role" />}
              InputProps={{ style: { color: "white" } }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "#C6A566" },
                  "&:hover fieldset": { borderColor: "#C6A566" },
                  "&.Mui-focused fieldset": { borderColor: "#C6A566" },
                },
                "& .MuiInputLabel-root": { color: "#C6A566" },
              }}
            >
              {roles.map((role) => (
                <MenuItem key={role.value} value={role.value}>
                  {role.label}
                </MenuItem>
              ))}
            </Field>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              fullWidth
            >
              {isSubmitting ? <CircularProgress color="inherit" /> : "Sign Up"}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
    <SnackbarUI open={open} setOpenSnack={setOpenSnack} content={content} severity={severity} />
    </>
  );
};

export default AdminSignup;
