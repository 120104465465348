import React, { useState } from "react";
import { AppBar, Tabs, Tab, Box } from "@mui/material";
import ArticlePanel from "./articlesPanel";
import { ThemeContextProvider } from "../../../themecontext";

const ArticlesTabs = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <ThemeContextProvider>
      <Box sx={{ width: "100%", mt: 8 }}>
        <AppBar position="static" color="transparent" elevation={0} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
          <Tabs
            value={activeTab}
            onChange={(event, newValue) => setActiveTab(newValue)}
            textColor="primary"
            indicatorColor="transparent"
            sx={{
              "& .MuiTab-root": {
                textTransform: "none",
                fontSize: "16px",
                fontWeight: 600,
                borderRadius: "12px",
                px: 3,
                py: 1,
              },
              "& .Mui-selected": {
                color: "white",
                backgroundColor: "#3b82f6",
                borderRadius: "12px",
              },
            }}
          >
            <Tab label="Articles" />
            <Tab label="Press Release" />
          </Tabs>
        </AppBar>

        <Box sx={{ mt: 3 }}>
          {activeTab === 0 && <ArticlePanel activeTab={activeTab} /> }
          {activeTab === 1 && <ArticlePanel activeTab={activeTab}/> }
        </Box>
      </Box>
    </ThemeContextProvider>
  );
};

export default ArticlesTabs;
