import React from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
} from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import ArticleIcon from "@mui/icons-material/Article";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { DrawerHeader } from "../../../utils/styledComponents";
import { ThemeContextProvider } from "../../../themecontext";
import logo from "../../../assets/GFL-logo2.svg";
import { MEDIA_CATEGORY } from "../../../utils/constants";

const drawerWidth = 240;

const SideNavBar = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = (path, search = "") => {
    const fullPath = `${path}${search}`;
    if (location.pathname + location.search === fullPath) {
      navigate("/temp", { replace: true }); // Temporary navigation
      setTimeout(() => navigate(fullPath, { replace: true }), 0); // Navigate back to force reload
    } else {
      navigate(fullPath);
    }
  };

  return (
    <ThemeContextProvider>
      <Drawer
        sx={{
          width: open ? drawerWidth : 0,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: open ? drawerWidth : 0,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <img src={logo} alt="Logo" height={60} />
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem
            button
            selected={location.pathname === "/admin/videos"}
            onClick={() => handleNavigation("/admin/videos")}
            sx={{
              cursor: "pointer",
              backgroundColor:
                location.pathname === "/admin/videos" ? "#C6A566" : "inherit",
              color:
                location.pathname === "/admin/videos" ? "white" : "inherit",
              "&.Mui-selected": {
                backgroundColor: "#C6A566",
                color: "white",
              },
              "&:hover": {
                backgroundColor: "#1a1a1a",
              },
            }}
          >
            <ListItemIcon>
              <PlayCircleOutlineIcon />
            </ListItemIcon>
            <ListItemText primary="Videos" />
          </ListItem>

          <ListItem
            button
            selected={
              location.pathname === "/admin/articles" &&
              location.search.includes(MEDIA_CATEGORY.ARTICLES)
            }
            onClick={() =>
              handleNavigation(
                "/admin/articles",
                `?category=${MEDIA_CATEGORY.ARTICLES}`
              )
            }
            sx={{
              cursor: "pointer",
              backgroundColor:
                location.pathname === "/admin/articles" &&
                location.search.includes(MEDIA_CATEGORY.ARTICLES)
                  ? "#C6A566"
                  : "inherit",
              color:
                location.pathname === "/admin/articles" &&
                location.search.includes(MEDIA_CATEGORY.ARTICLES)
                  ? "white"
                  : "inherit",
              "&.Mui-selected": {
                backgroundColor: "#C6A566",
                color: "white",
              },
              "&:hover": {
                backgroundColor: "#1a1a1a",
              },
            }}
          >
            <ListItemIcon>
              <ArticleIcon />
            </ListItemIcon>
            <ListItemText primary="News Articles" />
          </ListItem>

          <ListItem
            button
            selected={
              location.pathname === "/admin/articles" &&
              location.search.includes(MEDIA_CATEGORY.PRESS)
            }
            onClick={() =>
              handleNavigation(
                "/admin/articles",
                `?category=${MEDIA_CATEGORY.PRESS}`
              )
            }
            sx={{
              cursor: "pointer",
              backgroundColor:
                location.pathname === "/admin/articles" &&
                location.search.includes(MEDIA_CATEGORY.PRESS)
                  ? "#C6A566"
                  : "inherit",
              color:
                location.pathname === "/admin/articles" &&
                location.search.includes(MEDIA_CATEGORY.PRESS)
                  ? "white"
                  : "inherit",
              "&.Mui-selected": {
                backgroundColor: "#C6A566",
                color: "white",
              },
              "&:hover": {
                backgroundColor: "#1a1a1a",
              },
            }}
          >
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="Press Releases" />
          </ListItem>
          <ListItem
            button
            selected={location.pathname === "/admin/contact-us"}
            onClick={() => handleNavigation("/admin/contact-us")}
            sx={{
              cursor: "pointer",
              backgroundColor:
                location.pathname === "/admin/contact-us"
                  ? "#C6A566"
                  : "inherit",
              color:
                location.pathname === "/admin/contact-us" ? "white" : "inherit",
              "&.Mui-selected": {
                backgroundColor: "#C6A566",
                color: "white",
              },
              "&:hover": {
                backgroundColor: "#1a1a1a",
              },
            }}
          >
            <ListItemIcon>
              <PermContactCalendarIcon />
            </ListItemIcon>
            <ListItemText primary="Contact Us" />
          </ListItem>
          <ListItem
            button
            selected={location.pathname === "/admin/stay-in-the-know"}
            onClick={() => handleNavigation("/admin/stay-in-the-know")}
            sx={{
              cursor: "pointer",
              backgroundColor:
                location.pathname === "/admin/stay-in-the-know"
                  ? "#C6A566"
                  : "inherit",
              color:
                location.pathname === "/admin/stay-in-the-know"
                  ? "white"
                  : "inherit",
              "&.Mui-selected": {
                backgroundColor: "#C6A566",
                color: "white",
              },
              "&:hover": {
                backgroundColor: "#1a1a1a",
              },
            }}
          >
            <ListItemIcon>
              <CircleNotificationsIcon />
            </ListItemIcon>
            <ListItemText primary="Stay In The Know" />
          </ListItem>
        </List>
      </Drawer>
    </ThemeContextProvider>
  );
};

export default SideNavBar;
