import React, { useEffect, useState, useRef } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import ArticleForm from "./articlesForm";

import {
  DATA_STATUS,
  FORM_TYPE,
  MEDIA_CATEGORY,
} from "../../../utils/constants";
import { api } from "../../../utils/api";
import Button from "@mui/material/Button";
import TableCell from "@mui/material/TableCell";
import SnackbarUI from "../../common/snackbar";
import "../adminPanel.scss";
import DeleteDialog from "../DeleteDialog";
import { ThemeContextProvider } from "../../../themecontext";
import ArticleTable from "./articlesTable";
import { useSearchParams } from "react-router-dom";

const mediaCategory = ["Press", "Articles"];

const ArticlePanel = ({ activeTab }) => {
  const [searchParams] = useSearchParams();
  const categoryparams = searchParams.get("category");
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [category, setCategory] = useState(categoryparams);
  const [showTable, setShowTable] = useState(true);
  const [formType, setFormType] = useState(FORM_TYPE.ADD);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [editData, setEditData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [currentRowData, setCurrentRowData] = useState({});
  const [imageFile, setImageFile] = useState(null);
  const [openSnack, setOpenSnack] = useState(false);
  const [content, setContent] = useState("");
  const [severity, setSeverity] = useState("success");
  const [imageFileUrl, setImageFileUrl] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(DATA_STATUS.ACTIVE);
  const [tableDataReceived, setTableDataReceived] = useState(false);

  const [pdfFile, setPdfFile] = useState(null);
  const [pdfFileUrl, setPdfFileUrl] = useState("");
  const [newsLinkType, setNewsLinkType] = useState(
    categoryparams === "Articles" ? "link" : "pdf"
  );
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [errLink, setErrLink] = useState("");
  const [errImg, setErrImg] = useState("");
  const [errPdf, setErrPdf] = useState("");
  const formikRef = useRef(null);

  const navigate = useNavigate();

  const handleFileChange = (event, type) => {
    const file = event.target.files[0];
    if (!file) return;

    if (type === "image") {
      setImageFile(file);
      setErrImg("");
    } else if (type === "pdf") {
      setPdfFile(file);
      setErrPdf("");
    }
  };
  useEffect(() => {
    setOpen(false);
  }, []);

  const handleLinkChange = (event) => {
    setNewsLinkType(event.target.value);
  };

  useEffect(() => {
    if (selectedStatus === DATA_STATUS.INACTIVE) {
      setSelectedStatus(DATA_STATUS.ACTIVE);
    }
    if (!showTable) {
      setShowTable(true);
    }
    setTableDataReceived(false);
    setNewsLinkType(categoryparams === "Articles" ? "link" : "pdf");
  }, [categoryparams, category]);

  useEffect(() => {
    if (formType === FORM_TYPE.EDIT && editData && formikRef.current) {
      formikRef.current.setValues(editData);
      setNewsLinkType(editData.typeOfLink?.toLowerCase());
    }
  }, [formType, editData]);

  const uploadFile = async (file, type) => {
    if (!file) {
      alert("Please choose a file first");
      return;
    }

    try {
      let timestampToUse = new Date().getTime(); // Default timestamp
      const fileExtension = file.name.split(".").pop();

      if (formType === FORM_TYPE.EDIT) {
        const url = type === "pdf" ? editData.newsLink : editData.imageUrl;
        const baseUrl = process.env.REACT_APP_BASE_URL;
        const path = process.env.REACT_APP_FILE_PATH;

        if (url.includes(baseUrl) && url.includes(path)) {
          timestampToUse = url
            .substring(url.lastIndexOf("/") + 1)
            .split(".")[0];
        }
      }

      // Make API request for pre-signed URL
      const response = await api.post("/gfl/pre-signed-url", {
        fileName: `${timestampToUse}.${fileExtension}`,
        fileType: file.type,
        folderPath: `${process.env.REACT_APP_FILE_PATH}/${category === MEDIA_CATEGORY.ARTICLES ? "news" : "press"
          }/`,
      });

      const { presignedUrl, fileUrl } = response.data;

      // Upload file to S3
      const uploadResponse = await fetch(presignedUrl, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": file.type || "application/octet-stream",
        },
      });

      if (!uploadResponse.ok) {
        throw new Error(`Upload failed: ${uploadResponse.statusText}`);
      }

      // Update state
      if (type === "image") {
        setImageFileUrl(fileUrl);
      } else if (type === "pdf") {
        setPdfFileUrl(fileUrl);
      }

      return fileUrl;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("authToken");
        navigate("/admin/login");
      } else {
        setOpenSnack(true);
        setContent(error.message);
        setSeverity("error");
        console.error("Error uploading file:", error);
      }
    }
  };

  useEffect(() => {
    if (
      formType === FORM_TYPE.EDIT &&
      currentRowData &&
      (category === MEDIA_CATEGORY.PRESS ||
        category === MEDIA_CATEGORY.ARTICLES)
    ) {
      setImageFileUrl(currentRowData.imageUrl);
      setPdfFileUrl(currentRowData.newsLink);
    }
  }, [formType]);

  const formFieldsByCategory = {
    Articles: [
      { name: "title", fieldType: "textarea", label: "Title" },
      { name: "newsDate", fieldType: "date", label: "News Date" },
      { name: "timezone", fieldType: "drop", label: "Time Zone" },
      { name: "imageUrl", fieldType: "text", label: "Image URL" },
      { name: "newsLink", fieldType: "text", label: "News Link" },
      { name: "description", fieldType: "textarea", label: "Description" },
    ],
    Press: [
      { name: "imageUrl", fieldType: "text", label: "Image URL" },
      { name: "title", fieldType: "textarea", label: "Title" },
      { name: "newsDate", fieldType: "date", label: "News Date" },
      { name: "timezone", fieldType: "drop", label: "Time Zone" },
      { name: "newsLink", fieldType: "text", label: "News Link" },
      { name: "description", fieldType: "textarea", label: "Description" },
    ],
  };

  const getFormFields = (category) =>
    (formFieldsByCategory[category] || []).filter(
      (field) => field.name !== "imageUrl" && field.name !== "newsLink"
    );

  const renderTableHeaders = (category) => {
    if (!formFieldsByCategory[category]) return null;

    return formFieldsByCategory[category].map(({ name }) => (
      <TableCell key={name} align="center">
        {name.charAt(0).toUpperCase() + name.slice(1)}
      </TableCell>
    ));
  };

  const formInitialValues = () => {
    const obj = {};

    if (Array.isArray(formFieldsByCategory[category])) {
      formFieldsByCategory[category].forEach((field) => {
        if (field?.name) {
          obj[field.name] = "";
        }
      });
    }
    obj["timezone"] = categoryparams === MEDIA_CATEGORY.PRESS ? "EST" : "";

    return obj;
  };

  const handleToastClose = () => {
    setOpenSnack(false);
  };
  const handleCreate = () => {
    setShowTable(false);
    setFormType(FORM_TYPE.ADD);
  };

  const handleEdit = async (row) => {
    setEditData(row);
    setImageFileUrl(row.imageUrl);
    setPdfFileUrl(row.newsLink);
    setShowTable(false);
    setFormType(FORM_TYPE.EDIT);
  };

  const handleStatusChange = (event) => {
    setTableDataReceived(false);
    const newStatus = event.target.value;
    setSelectedStatus(newStatus);
    applyFilters(tableData, newStatus);
    setPage(0);
  };
  const handleDeleteYes = async (value) => {
    try {
      const response = await api.put(
        `news/status/${currentRowData.newsReleaseId}`,
        {
          dataStatus: value === "I" ? DATA_STATUS.ACTIVE : DATA_STATUS.INACTIVE,
        }
      );
      if (response.status === 200) {
        console.log("Delete successful:", response.data);
        fetchData();
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("authToken");
        navigate("/admin/login");
      } else {
        setOpenSnack(true);
        setContent(error.message);
        setSeverity("error");
        console.error("Error:", error);
      }
    }
    setCurrentRowData({});
    setShowDeleteDialog(false);
  };

  const handleDeleteNo = () => {
    setCurrentRowData({});
    setShowDeleteDialog(false);
  };

  const filterNews = (tableData) => {
    const filteredNews = tableData?.filter(
      (news) => news?.typeOfNews === category
    );
    setFilteredTableData(filteredNews);
  };
  const applyFilters = (data, selectedStatus) => {
    if (!Array.isArray(data)) return;
    const filtered = data.filter((news) => news.dataStatus === selectedStatus);
    setFilteredData(filtered);
  };
  const fetchData = async () => {
    try {
      let response;
      if (
        category === MEDIA_CATEGORY.ARTICLES ||
        category === MEDIA_CATEGORY.PRESS
      ) {
        response = await api.post("/news/all", {
          dataStatus: selectedStatus,
          typeOfNews: categoryparams,
          limit: rowsPerPage,
          pageNumber: page + 1,
        });
      }

      if (response.status === 200) {
        console.log("Success:", response.data);
        const sortedPress = response.data.data.sort(
          (a, b) => new Date(b.newsDate) - new Date(a.newsDate)
        );
        setTableData(sortedPress);
        setTableDataReceived(true);
        setTotalCount(response.data.metadata?.totalCount || 0);
        // filterNews(response.data.data);
        applyFilters(sortedPress, selectedStatus);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("authToken");
        navigate("/admin/login");
      } else {
        setOpenSnack(true);
        setContent(error.message);
        setTotalCount(0);
        setSeverity("error");
        console.error("Error:", error);
      }
    }
  };

  useEffect(() => {
    setPage(0);
    setRowsPerPage(10);
  }, [categoryparams]);

  useEffect(() => {
    if (tableData.length > 0) {
      applyFilters(tableData, selectedStatus);
    }
  }, [tableData, selectedStatus]);

  useEffect(() => {
    fetchData();
  }, [category, selectedStatus, categoryparams, page, rowsPerPage]);

  function createData(category, data) {
    switch (category) {
      case MEDIA_CATEGORY.ARTICLES:
      case MEDIA_CATEGORY.PRESS:
        return data.map(
          ({
            imageUrl,
            title,
            newsDate,
            newsLink,
            description,
            newsReleaseId,
          }) => ({
            imageUrl,
            title,
            newsDate,
            newsLink,
            description,
            newsReleaseId,
          })
        );

      default:
        return [];
    }
  }
  const capitalizeFirstLetter = (str) =>
    str.charAt(0).toUpperCase() + str.slice(1);

  const resetStates = () => {
    setShowTable(true);
    setCurrentRowData({});
    setFormType(FORM_TYPE.ADD);
    setPdfFileUrl("");
    setImageFileUrl("");
    setNewsLinkType(categoryparams === "Articles" ? "link" : "pdf");
    setImageFile(null);
    setPdfFile(null);
    setErrLink("");
    setErrImg("");
    setErrPdf("");
    setEditData(null);
  };

  const handleFormSubmit = async (values) => {
    if (newsLinkType === "link" && pdfFileUrl === "") {
      setErrLink("News link is required");
      return;
    } else if (
      newsLinkType === "pdf" &&
      ((pdfFile === null && formType === FORM_TYPE.ADD) ||
        (formType === FORM_TYPE.EDIT && pdfFile === null && pdfFileUrl === ""))
    ) {
      setErrPdf("Pdf is required");
      return;
    }
    if (imageFileUrl === "" && imageFile === null) {
      setErrImg("Image is required");
      return;
    }

    const linkType = capitalizeFirstLetter(newsLinkType);
    let uploadedImageUrl = imageFileUrl;
    let uploadedPdfUrl = pdfFileUrl;

    if (imageFile) {
      uploadedImageUrl = await uploadFile(imageFile, "image");
    }
    if (pdfFile) {
      uploadedPdfUrl = await uploadFile(pdfFile, "pdf");
    }

    const updatedValues = {
      ...values,
      newsDate: new Date(values.newsDate).toISOString(),
      imageUrl: uploadedImageUrl,
      newsLink: uploadedPdfUrl,
      typeOfNews: categoryparams,
      dataStatus: selectedStatus,
      typeOfLink: linkType,
    };

    try {
      let response;
      if (formType === FORM_TYPE.ADD) {
        response = await api.post(`/news`, updatedValues);
      } else if (formType === FORM_TYPE.EDIT) {
        const { newsReleaseId, ...newObject } = updatedValues;
        response = await api.put(`/news/${newsReleaseId}`, newObject);
      }
      if (response.status === 200 || response.status === 201) {
        fetchData();
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("authToken");
        navigate("/login");
      } else {
        setOpenSnack(true);
        setContent(error.message);
        setSeverity("error");
        console.error("Error:", error);
      }
    }
    resetStates();
  };
  const formatText = (text) => {
    if (!text) return "";
    return text
      .toLowerCase()
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const tableRows = createData(category, filteredTableData);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="py-4">
      {showTable && (
        <div className="row align-content-center pb-2">
          <div className="col-md-6">
            <h2>
              {" "}
              {categoryparams == "Press" ? "Press Releases" : "News Articles"}
            </h2>
          </div>
          <div className="col-md-6 text-md-end">
            <ThemeContextProvider>
              <FormControl
                style={{ minWidth: 100 }}
                size="small"
                className="text-start"
                variant="outlined"
              >
                <InputLabel shrink>Status</InputLabel>
                <Select
                  value={selectedStatus}
                  onChange={handleStatusChange}
                  label="Status"
                >
                  {Object.entries(DATA_STATUS).map(([key, value]) => {
                    const formattedKey =
                      key.charAt(0).toUpperCase() + key.slice(1).toLowerCase();
                    return (
                      <MenuItem key={key} value={value}>
                        {formattedKey}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </ThemeContextProvider>
            <Button variant="contained" className="ms-3" onClick={handleCreate}>
              Create
            </Button>
          </div>
        </div>
      )}
      {showTable ? (
        <>
          <ThemeContextProvider>
            <ArticleTable
              renderTableHeaders={renderTableHeaders}
              tableHeaders={[
                "Image",
                "Title",
                "Published Date",
                categoryparams === MEDIA_CATEGORY.PRESS
                  ? "Press Releases Link"
                  : "News Link",
                "Description",
              ]}
              category={categoryparams}
              tableRows={tableRows}
              setShowDeleteDialog={setShowDeleteDialog}
              setCurrentRowData={setCurrentRowData}
              handleEdit={handleEdit}
              selectedStatus={selectedStatus}
              filteredData={filteredData}
              totalCount={totalCount}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              tableDataReceived={tableDataReceived}
            />
          </ThemeContextProvider>
        </>
      ) : (
        <div>
          <h2>
            {`${formatText(
              formType === FORM_TYPE.ADD ? FORM_TYPE.ADD : FORM_TYPE.EDIT
            )} ${formatText(
              categoryparams === "Press" ? "Press Releases" : "News Articles"
            )}`}
          </h2>
          <hr className="mt-0 mb-4"></hr>
          <ArticleForm
            handleFormSubmit={handleFormSubmit}
            formInitialValues={formInitialValues}
            getFormFields={getFormFields}
            category={categoryparams}
            newsLinkType={newsLinkType}
            setNewsLinkType={setNewsLinkType}
            handleLinkChange={handleLinkChange}
            imageFileUrl={imageFileUrl}
            currentRowData={currentRowData}
            pdfFileUrl={pdfFileUrl}
            setPdfFileUrl={setPdfFileUrl}
            handleFileChange={handleFileChange}
            uploadFile={uploadFile}
            formType={formType}
            setShowTable={setShowTable}
            formikRef={formikRef}
            imageFile={imageFile}
            pdfFile={pdfFile}
            setPdfFile={setPdfFile}
            errLink={errLink}
            errImg={errImg}
            errPdf={errPdf}
            setErrLink={setErrLink}
            resetStates={resetStates}
            editData={editData}
          />
        </div>
      )}

      {showDeleteDialog && (
        <DeleteDialog
          handleDeleteYes={handleDeleteYes}
          handleDeleteNo={handleDeleteNo}
          category={category}
          categoryparams={categoryparams}
          showDeleteDialog={showDeleteDialog}
          setShowDeleteDialog={setShowDeleteDialog}
          selectedStatus={selectedStatus}
        />
      )}
      <SnackbarUI
        open={openSnack}
        setOpenSnack={setOpenSnack}
        content={content}
        severity={severity}
        onClose={handleToastClose}
        autoHideDuration={3000}
      />
    </div>
  );
};
export default ArticlePanel;
