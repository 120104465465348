import React, { useEffect, useState, useRef } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";
import {
  DATA_STATUS,
  FORM_TYPE,
  MEDIA_CATEGORY,
} from "../../../utils/constants";
import {
  Main,
  AppBar,
  DrawerHeader,
  drawerWidth,
} from "../../../utils/styledComponents";
import { api } from "../../../utils/api";
import styledComponents from "../../../utils/styledComponents";

import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  List,
  ListItem,
} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { TableCell } from "@mui/material";
import "../adminPanel.scss";
import { ThemeContextProvider } from "../../../themecontext";
import VideoTable from "./videosTable";
import DeleteDialog from "../DeleteDialog";
import MediaTable from "../MediaTable";
//import AdminForm from "../AdminForm";
import VideosForm from "./videoForm";
import SnackbarUI from "../../common/snackbar";
import CssBaseline from "@mui/material/CssBaseline";

const mediaCategory = ["Videos", "Press", "Articles"];

const VideoPanel = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [category, setCategory] = useState(mediaCategory[0]);
  const [showTable, setShowTable] = useState(true);
  const [formType, setFormType] = useState(FORM_TYPE.ADD);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [editData, setEditData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [tableDataReceived, setTableDataReceived] = useState(false);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [currentRowData, setCurrentRowData] = useState({});
  const [imageFile, setImageFile] = useState(null);
  const [openSnack, setOpenSnack] = useState(false);
  const [content, setContent] = useState("");
  const [severity, setSeverity] = useState("success");
  const formikRef = useRef(null);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(DATA_STATUS.ACTIVE);

  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const navigate = useNavigate();

  useEffect(() => {
    if (formType === FORM_TYPE.EDIT && editData && formikRef.current) {
      formikRef.current.setValues(editData);
    }
  }, [formType, editData]);

  useEffect(() => {
    setOpen(false);
  }, []);

  const timeStamp = new Date().getTime();

  const formFieldsByCategory = {
    Videos: [
      { name: "youtubeLink", fieldType: "text" },
      { name: "title", fieldType: "textarea" },
    ],
  };
  const renderTableHeaders = (category) => {
    if (!formFieldsByCategory[category]) return null;

    return formFieldsByCategory[category].map(({ name }) => (
      <TableCell key={name} align="center">
        {name.charAt(0).toUpperCase() + name.slice(1)}
      </TableCell>
    ));
  };

  const formInitialValues = () => {
    const obj = {};

    if (Array.isArray(formFieldsByCategory[category])) {
      formFieldsByCategory[category].forEach((field) => {
        if (field?.name) {
          obj[field.name] = "";
        }
      });
    }

    return obj;
  };

  const handleStatusChange = (event) => {
    setTableDataReceived(false)
    setFilteredData([])
    const newStatus = event.target.value;
    setSelectedStatus(newStatus);

    setPage(0);
  };

  const handleToastClose = () => {
    setOpenSnack(false);
  };

  const handleCreate = () => {
    setEditData(null);
    setFormType(FORM_TYPE.ADD);
    setShowTable(false);
  };

  const handleEdit = async (row) => {
    setEditData(row);
    setShowTable(false);
    setFormType(FORM_TYPE.EDIT);
  };

  const handleDeleteYes = async (value) => {
    if (category == "Videos") {
      try {
        const response = await api.put(
          `video/status/${currentRowData.videoLinkId}`,
          {
            dataStatus: value === "I" ? DATA_STATUS.ACTIVE : DATA_STATUS.INACTIVE,
          }
        );
        if (response.status === 200) {
          console.log("Delete successful:", response.data);
          fetchData();
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("authToken");
          navigate("/admin/login");
        } else {
          setOpenSnack(true);
          setContent("Failed to delete video");
          setSeverity("error");
          console.error("Error deleting video:", error);
        }
      }
    }
    setCurrentRowData({});
    setShowDeleteDialog(false);
  };
  const handleDeleteNo = () => {
    setCurrentRowData({});
    setShowDeleteDialog(false);
  };

  const applyFilters = (data, selectedStatus) => {
    if (!Array.isArray(data)) return;
    const filtered = data.filter((news) => news.dataStatus === selectedStatus);
    setFilteredData(filtered);
  };

  const fetchData = async () => {
    try {
      let response;
      if (category === MEDIA_CATEGORY.VIDEOS) {
        response = await api.post("/video/all", {
          dataStatus: selectedStatus,
          limit: rowsPerPage,
          pageNumber: page + 1,
        });
      }

      if (response?.status === 200 && response.data) {
        const sortedVids = response.data.data.sort((a, b) => new Date(b.updatedOn) - new Date(a.updatedOn));
        setTableData(sortedVids || []);
        setTableDataReceived(true);
        setTotalCount(response.data.metadata?.totalCount || 0);
        applyFilters(sortedVids, selectedStatus);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("authToken");
        navigate("/admin/login");
      } else {
        setTableData([]);
        setTotalCount(0);
        setOpenSnack(true);
        setContent("Failed to fetch data");
        setSeverity("error");
        console.error("Error fetching data:", error);
      }

    }
  };


  useEffect(() => {
    fetchData();
  }, [category, selectedStatus, page, rowsPerPage]);

  function createData(category, data) {
    switch (category) {
      case MEDIA_CATEGORY.VIDEOS:
        return data.map(({ youtubeLink, title, videoLinkId }) => ({
          youtubeLink,
          title,
          videoLinkId,
        }));
      default:
        return [];
    }
  }

  function getYouTubeEmbedURL(url) {
    if (url.includes("youtube.com/embed/")) {
      return url; // Return as is if it's already an embed URL
    }
    let videoId = "";
    let regex = /(?:youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]+)/;
    let match = url.match(regex);
    if (match && match[1]) {
      videoId = match[1];
    }
    return videoId ? `https://www.youtube.com/embed/${videoId}` : null;
  }

  const handleFormSubmit = async (values) => {

    let updatedValues = {
      ...values,
      youtubeLink: getYouTubeEmbedURL(values.youtubeLink),
      updatedOn: new Date()
    };

    try {
      if (formType === FORM_TYPE.ADD) {
        const response = await api.post(`/video`, updatedValues);
        if (response.status === 201) {
          fetchData();
        }
      } else if (formType === FORM_TYPE.EDIT) {
        const { videoLinkId, ...newObject } = updatedValues;
        const response = await api.put(`/video/${videoLinkId}`, newObject);
        if (response.status === 200) {
          console.log("Video successfully updated:", response.data);
          fetchData();
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("authToken");
        navigate("/admin/login");
      } else {
        setOpenSnack(true);
        setContent(error.message);
        setSeverity("error");
        console.error("Error:", error);
      }
    }

    setShowTable(true);
    setCurrentRowData({});
    setFormType(FORM_TYPE.ADD);
  };
  const formatText = (text) => {
    if (!text) return "";
    return text
      .toLowerCase()
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const tableRows = createData(category, filteredTableData || []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="py-4">
      {showTable && (
        <div className="row align-content-center pb-2">
          <div className="col-md-6">
            <h2> {category}</h2>
          </div>
          <div className="col-md-6 text-md-end">
            <ThemeContextProvider>
              <FormControl
                style={{ minWidth: 100 }}
                size="small"
                className="text-start"
                variant="outlined"
              >
                <InputLabel shrink>Status</InputLabel>
                <Select
                  value={selectedStatus}
                  onChange={handleStatusChange}
                  label="Status"
                >
                  {Object.entries(DATA_STATUS).map(([key, value]) => {
                    const formattedKey =
                      key.charAt(0).toUpperCase() + key.slice(1).toLowerCase();
                    return (
                      <MenuItem key={key} value={value}>
                        {formattedKey}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </ThemeContextProvider>
            <Button variant="contained" className="ms-3" onClick={handleCreate}>
              Create
            </Button>
          </div>
        </div>
      )}
      {showTable ? (
        <>
          <ThemeContextProvider>
            <VideoTable
              renderTableHeaders={renderTableHeaders}
              tableHeaders={["Title", "YouTube Link"]}
              category={MEDIA_CATEGORY.VIDEOS}
              tableRows={tableRows}
              setShowDeleteDialog={setShowDeleteDialog}
              setCurrentRowData={setCurrentRowData}
              filteredData={filteredData}
              handleEdit={handleEdit}
              open={open}
              totalCount={totalCount}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              tableDataReceived={tableDataReceived}
              selectedStatus={selectedStatus}
            />
          </ThemeContextProvider>
        </>
      ) : (
        <div>
          <h2>
            {`${formatText(
              formType === FORM_TYPE.ADD ? FORM_TYPE.ADD : FORM_TYPE.EDIT
            )} ${formatText(category)}`}
          </h2>
          <hr className="mt-0 mb-4"></hr>
          <VideosForm
            handleFormSubmit={handleFormSubmit}
            formInitialValues={formInitialValues}
            category={category}
            currentRowData={currentRowData}
            formType={formType}
            setShowTable={setShowTable}
            formikRef={formikRef}
          />
        </div>
      )}

      {showDeleteDialog && (
        <DeleteDialog
          handleDeleteYes={handleDeleteYes}
          handleDeleteNo={handleDeleteNo}
          category={category}
          showDeleteDialog={showDeleteDialog}
          setShowDeleteDialog={setShowDeleteDialog}
          selectedStatus={selectedStatus}
        />
      )}
      <SnackbarUI
        open={openSnack}
        setOpenSnack={setOpenSnack}
        content={content}
        severity={severity}
        onClose={handleToastClose}
        autoHideDuration={3000}
      />
    </div>
  );
};
export default VideoPanel;
