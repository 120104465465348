import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import React from "react";
import Button from "@mui/material/Button";
import { ThemeContextProvider } from "../../../themecontext";

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  youtubeLink: Yup.string()
    .url("Invalid URL format")
    .required("YouTube Link is required"),
});

const VideosForm = ({ handleFormSubmit, formInitialValues, formType, setShowTable, formikRef }) => {
  return (
    <ThemeContextProvider>
      <Formik
        innerRef={formikRef}
        initialValues={formInitialValues()}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({ values, handleChange, errors, touched }) => (
          <Form>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <TextField
                    id="title"
                    label="Title"
                    variant="outlined"
                    className="w-100"
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    error={touched.title && Boolean(errors.title)}
                    helperText={touched.title && errors.title}
                  />
                </div>
                <div className="mb-3">
                  <TextField
                    id="youtubeLink"
                    label="YouTube Link"
                    variant="outlined"
                    className="w-100"
                    name="youtubeLink"
                    value={values.youtubeLink}
                    onChange={handleChange}
                    error={touched.youtubeLink && Boolean(errors.youtubeLink)}
                    helperText={touched.youtubeLink && errors.youtubeLink}
                  />
                </div>
                <Button type="submit" variant="contained" color="primary" className="me-3" size="large">
                  {formType === "EDIT" ? "Update" : "Submit"}
                </Button>
                <Button onClick={() => setShowTable(true)} variant="contained" color="secondary" size="large">
                  Close
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </ThemeContextProvider>
  );
};

export default VideosForm;
