
import React, { useState } from "react";
import { Box, CssBaseline } from "@mui/material";
import { Outlet } from "react-router-dom";
import Header from "./dashboardHeader/dashboardHeader";
import SideNavBar from "./sideNavBar/sideNavBar";

const AdminPanel = () => {
  const [open, setOpen] = useState(true);

  return (
    <Box sx={{ display: "flex", width: "100%", height: "100vh", background: "#191919" }}>
      <CssBaseline />
      <Header open={open} setOpen={setOpen} />
      <SideNavBar open={open} setOpen={setOpen} />

      <Box sx={{ flexGrow: 1, p: 3, overflow: "auto", paddingTop:"64px" }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default AdminPanel;
