import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Snackbar,
  Tooltip,
  Button,
  TableFooter,
  TablePagination,
  IconButton,
} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { MdDeleteOutline } from "react-icons/md";
import { MdOutlineModeEdit } from "react-icons/md";
import { TbCopyCheckFilled } from "react-icons/tb";
import DeleteIcon from "@mui/icons-material/Delete";
import VideoDialog from "./videosDialog";
import TablePaginationActions from "../paginationActions/TablePaginationActions";

const VideoTable = ({
  setShowDeleteDialog,
  setCurrentRowData,
  handleEdit,
  selectedStatus,
  filteredData,
  tableHeaders,
  totalCount,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
  tableDataReceived,
}) => {
  const [tableData, setTableData] = useState([]);
  const [openSnack, setOpenSnack] = useState(false);
  const [content, setContent] = useState("");
  const [severity, setSeverity] = useState("error");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState({ title: "", link: "" });

  const handleOpenDialog = (title, videoLink) => {
    setSelectedVideo({ title: title, link: videoLink });
    setOpenDialog(true);
  };

  const renderTooltip = (text, maxLength = 10) => (
    <Tooltip title={text} arrow>
      {text.length > maxLength ? `${text.substring(0, maxLength)}...` : text}
    </Tooltip>
  );

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header, index) => (
                <TableCell key={index}>{header}</TableCell>
              ))}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {!tableDataReceived && (
              <TableRow>
                <TableCell colSpan={6}>
                  <div className="text-center">
                    <CircularProgress color="inherit" />
                  </div>
                </TableCell>
              </TableRow>
            )}
            {filteredData.length > 0 ? (
              filteredData.map((row) => (
                <TableRow key={row.videoLinkId}>
                  <TableCell>{row.title}</TableCell>
                  <TableCell>
                    <a
                      href={row.youtubeLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(e) => {
                        e.preventDefault();
                        handleOpenDialog(row.title, row.youtubeLink);
                      }}
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      Watch
                    </a>
                  </TableCell>

                  <TableCell style={{ width: 140 }}>
                  {selectedStatus==="A" ?(<>
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        setShowDeleteDialog(true);
                        setCurrentRowData(row);
                      }}
                      color="primary"
                    >
                      <DeleteIcon />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        handleEdit(row);
                        setCurrentRowData(row);
                      }}
                      color="primary"
                    >
                      <MdOutlineModeEdit />
                    </IconButton></>):
                    (
                      <TbCopyCheckFilled 
  onClick={() => {
    setShowDeleteDialog(true);
    setCurrentRowData(row);
  }} 
  style={{ fontSize: "1.8em", color: "#C6A566" }} 
/>

                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (tableDataReceived && filteredData.length === 0)? (
              <TableRow>
                <TableCell colSpan={4}>No data available</TableCell>
              </TableRow>
            ) : null}
          </TableBody>
          <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              colSpan={tableHeaders.length + 1}
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
              }}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
              ActionsComponent={TablePaginationActions}
              sx={{
                "& .MuiTablePagination-menuItem": {
                  textAlign: "center",
                },
              }}
            />
          </TableRow>
        </TableFooter>
        </Table>
      </TableContainer>
      <VideoDialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        title={selectedVideo.title}
        videoLink={selectedVideo.link}
      />
      <Snackbar
        open={openSnack}
        autoHideDuration={3000}
        onClose={() => setOpenSnack(false)}
        message={content}
        severity={severity}
      />
    </div>
  );
};

export default VideoTable;
