import React, { useEffect, useState } from "react";
import Footer from "../footer/footer";
import { Helmet } from "react-helmet-async";
import Box from "@mui/material/Box";
import { api2 } from "../../utils/api";
import SnackbarUI from "../common/snackbar";
import CircularProgress from "@mui/material/CircularProgress";
import { DATA_STATUS, MEDIA_CATEGORY } from "../../utils/constants";
import "./media.scss";

const Press = () => {
  const [pressReleases, setPressReleases] = useState([]);
  const [openSnack, setOpenSnack] = useState(false);
  const [content, setContent] = useState("");
  const [severity, setSeverity] = useState("success");
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await api2.post("/news/all-news", {
        dataStatus: DATA_STATUS.ACTIVE,
        limit: 0,
        pageNumber: 0,
        typeOfNews: MEDIA_CATEGORY.PRESS,
      });
      const sortedPress = response.data.data.sort(
        (a, b) => new Date(b.newsDate) - new Date(a.newsDate)
      );
      setPressReleases(sortedPress);
    } catch (error) {
      console.error("Error fetching press releases:", error);
      setOpenSnack(true);
      setContent(
        error.response?.data?.message || "Failed to fetch press releases"
      );
      setSeverity("error");
    } finally {
      setShowLoader(false);
    }
  };

  return (
    <div>
      <Helmet>
        <title>About GFL MMA League | Revolutionizing Mixed Martial Arts</title>
        <meta
          name="description"
          content="Learn about the GFL MMA League, our mission to transform mixed martial arts, and how we’re creating new opportunities for athletes and fans in 2025."
        />
        <meta
          name="keywords"
          content="about GFL MMA league,GFL MMA 2025,MMA athlete-focused league,MMA sports organization,MMA combat sports 2025,GFL mission MMA,GFL athlete comeback stories,GFL MMA vision,MMA revenue sharing model,team-based MMA league"
        />
      </Helmet>
      <div className="media">
        <div className="container">
          <h1 className="text-center pb-4">Press Releases</h1>
          <Box sx={{ width: "100%", typography: "body1" }}>
            {showLoader && (
              <div className="text-center">
                <CircularProgress color="inherit" />
              </div>
            )}
            {!showLoader && pressReleases.length === 0 && (
              <p className="text-center">No press releases available</p>
            )}
            {/* <div className="pt-4">
              {pressReleases.map((card, index) => (
                <div className="pr-card pb-4" key={index}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="pr-img">
                        <img src={card.imageUrl} className="mw-100" alt={card.title} />
                      </div>
                    </div>
                    <div className="col-md-6 py-4">
                      <h3>{card.title}</h3>
                      <div className="pb-2 date">
                        {new Date(card.newsDate).toDateString()}{" "}
                        {card.timezone && card.timezone !== null ? card.timezone : ""}
                      </div>                      
                      <p>{card.description}</p>
                      <a href={card.newsLink} target="_blank" className="read-more" rel="noopener noreferrer">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div> */}
            <div className="pt-4">
              {pressReleases.map((card, index) => {
                const dateTime = new Date(card.newsDate);
                const formattedDate = dateTime.toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                });
                const formattedTime = dateTime.toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                });
                const showTime = formattedTime !== "12:00 AM";

                return (
                  <div className="pr-card pb-4" key={index}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="pr-img">
                          <img
                            src={card.imageUrl}
                            className="mw-100"
                            alt={card.title}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 py-4">
                        <h3>{card.title}</h3>
                        <div className="pb-2 date">
                          {formattedDate}
                          {showTime &&
                            ` at ${formattedTime} ${card.timezone && card.timezone !== null
                              ? card.timezone
                              : ""
                            }`}
                        </div>
                        <p>{card.description}</p>
                        <a
                          href={card.newsLink}
                          target="_blank"
                          className="read-more"
                          rel="noopener noreferrer"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            {/* <div className="pt-4">
              {
                pressContent.map((card, index) => (
                  <div className="pr-card pb-4" key={index}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="pr-img">
                          <img src={imageMapping[card.src]} className="mw-100" alt="Card visual" />
                        </div>
                      </div>
                      <div className="col-md-6 py-4">
                        <h3>{card.title}</h3>
                        <div className="pb-2 date">{card.date}</div>
                        <p>{card.content}</p>
                        <a href={card.readMoreLink} target="_blank" className="read-more">
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div> */}
          </Box>
        </div>
      </div>
      <Footer />
      <SnackbarUI
        open={openSnack}
        setOpenSnack={setOpenSnack}
        content={content}
        severity={severity}
        onClose={() => setOpenSnack(false)}
        autoHideDuration={3000}
      />
    </div>
  );
};

export default Press;
