import React, { useEffect, useState } from "react";
import { Alert, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "../adminPanel.scss";
import { ThemeContextProvider } from "../../../themecontext";
import ContactTable from "./contactTable";
import { api } from "../../../utils/api";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const ContactUs = () => {
  const navigate = useNavigate();

  // Set default dates (one week ago to today)
  const [fromDate, setFromDate] = useState(
    dayjs().subtract(7, "day").format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(dayjs().format("YYYY-MM-DD"));

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [tableDataReceived, setTableDataReceived] = useState(false);

  const handleExportCSV = async () => {
    if (!fromDate || !toDate) {
      <Alert severity="warning">Please select both From and To dates.</Alert>;
      return;
    }

    try {
      const response = await api.post(
        "/gfl/contact-us-requests/all?csv=true",
        { fromDate, toDate },
        { responseType: "blob" } // Get raw binary data
      );

      if (response.status === 200) {
        const blob = new Blob([response.data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = `contact_us_requests_${fromDate}_to_${toDate}.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        window.URL.revokeObjectURL(url);
      } else {
        console.error("CSV export failed.");
      }
    } catch (error) {
      console.error("Error exporting CSV:", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchData = async () => {
    try {
      const response = await api.post("/gfl/contact-us-requests/all", {
        limit: rowsPerPage,
        pageNumber: page + 1,
        fromDate, // Include fromDate
        toDate, // Include toDate
      });

      if (response?.status === 200 && response.data) {
        setTableDataReceived(true);
        const filteredData = response.data.data.map(
          ({ name, email, phoneNumber, message, createdOn }) => ({
            name,
            email,
            message,
            phoneNumber,
            createdOn: dayjs(createdOn).format("DD MMMM YYYY"), // Ensure key name is explicitly mentioned
          })
        );
        setTableData(filteredData);
        setTotalCount(response.data.metadata?.totalCount || 0);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("authToken");
        navigate("/admin/login");
      } else {
        setTableData([]);
        setTotalCount(0);
        console.error("Error fetching data:", error);
      }
    }
  };

  // Fetch data when page, rowsPerPage, fromDate, or toDate changes
  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, fromDate, toDate]);

  return (
    <div className="py-4">
      <div className="row align-content-center pb-2">
        <div className="col-md-6">
          <h2>Contact Us</h2>
        </div>
        <div className="col-md-6 text-md-end d-flex gap-3 justify-content-end">
          <ThemeContextProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div className="d-flex gap-3">
                {/* From Date */}
                <DatePicker
                  label="From Date"
                  value={fromDate ? dayjs(fromDate) : null}
                  maxDate={dayjs()}
                  onChange={(newValue) =>
                    setFromDate(newValue ? newValue.format("YYYY-MM-DD") : "")
                  }
                />

                {/* To Date */}
                <DatePicker
                  label="To Date"
                  value={toDate ? dayjs(toDate) : null}
                  minDate={fromDate ? dayjs(fromDate) : null}
                  maxDate={dayjs()}
                  onChange={(newValue) =>
                    setToDate(newValue ? newValue.format("YYYY-MM-DD") : "")
                  }
                />
              </div>
            </LocalizationProvider>
            <Button
              variant="contained"
              onClick={handleExportCSV}
              sx={{ whiteSpace: "nowrap", minWidth: "150px" }}
            >
              Export as CSV
            </Button>
          </ThemeContextProvider>
        </div>
      </div>
      <ThemeContextProvider>
        <ContactTable
          tableHeaders={[
            "Name",
            "Email",
            "Message",
            "Phone Number",
            "Created On",
          ]}
          tableData={tableData}
          totalCount={totalCount}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          tableDataReceived={tableDataReceived}
        />
      </ThemeContextProvider>
    </div>
  );
};

export default ContactUs;
