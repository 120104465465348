// EndPoint
export const apiEndPoint = process.env.REACT_APP_API_URL
export const headers = { headers: { 'Content-Type': 'application/json', }, withCredentials: true, }
export const platformKombatPlus = process.env.REACT_APP_KOMBATPLUS_PLATFORM_URL

export const DATA_STATUS = {
    ACTIVE: "A",
    INACTIVE: "I",
    // DELETED: "X",
    // PENDING: "P",
};

export const FORM_TYPE={
    ADD:"add",
    EDIT:"edit",
};

export const MEDIA_CATEGORY={
    ARTICLES:"Articles",
    PRESS:"Press",
    VIDEOS:"Videos"
};

export const timeZones = [
    { label: "UTC (Coordinated Universal Time)", value: "UTC" },
  
    // North American Time Zones
    { label: "EST (Eastern Standard Time)", value: "EST" },
    { label: "EDT (Eastern Daylight Time)", value: "EDT" },
    { label: "CST (Central Standard Time)", value: "CST" },
    { label: "CDT (Central Daylight Time)", value: "CDT" },
    { label: "MST (Mountain Standard Time)", value: "MST" },
    { label: "MDT (Mountain Daylight Time)", value: "MDT" },
    { label: "PST (Pacific Standard Time)", value: "PST" },
    { label: "PDT (Pacific Daylight Time)", value: "PDT" },
    { label: "AKST (Alaska Standard Time)", value: "AKST" },
    { label: "AKDT (Alaska Daylight Time)", value: "AKDT" },
    { label: "HST (Hawaii Standard Time)", value: "HST" },
  
    // European Time Zones
    { label: "GMT (Greenwich Mean Time)", value: "GMT" },
    { label: "BST (British Summer Time)", value: "BST" },
    { label: "CET (Central European Time)", value: "CET" },
    { label: "CEST (Central European Summer Time)", value: "CEST" },
    { label: "EET (Eastern European Time)", value: "EET" },
    { label: "EEST (Eastern European Summer Time)", value: "EEST" },
    { label: "MSK (Moscow Standard Time)", value: "MSK" },
    { label: "MSD (Moscow Daylight Time)", value: "MSD" },
  
    // Asian Time Zones
    { label: "IST (Indian Standard Time)", value: "IST" },
    { label: "CST (China Standard Time)", value: "CST" },
    { label: "JST (Japan Standard Time)", value: "JST" },
    { label: "KST (Korea Standard Time)", value: "KST" },
    { label: "PHT (Philippine Time)", value: "PHT" },
    { label: "SGT (Singapore Time)", value: "SGT" },
  
    // Australian Time Zones
    { label: "AEST (Australian Eastern Standard Time)", value: "AEST" },
    { label: "AEDT (Australian Eastern Daylight Time)", value: "AEDT" },
    { label: "ACST (Australian Central Standard Time)", value: "ACST" },
    { label: "ACDT (Australian Central Daylight Time)", value: "ACDT" },
    { label: "AWST (Australian Western Standard Time)", value: "AWST" },
  
    // Other Time Zones
    { label: "NZST (New Zealand Standard Time)", value: "NZST" },
    { label: "NZDT (New Zealand Daylight Time)", value: "NZDT" },
    { label: "FJT (Fiji Time)", value: "FJT" },
    { label: "TKT (Tokelau Time)", value: "TKT" },
    { label: "SST (Samoa Standard Time)", value: "SST" },
    { label: "LINT (Line Islands Time)", value: "LINT" },
  ];