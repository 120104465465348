import React, { useState } from "react";
import { FORM_TYPE, MEDIA_CATEGORY, timeZones } from "../../../utils/constants";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import {
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Button,
  Stack,
  Box,
  Autocomplete,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
} from "@mui/material";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { ThemeContextProvider } from "../../../themecontext";
import "./article.scss";

const ArticleForm = ({
  handleFormSubmit,
  formInitialValues,
  getFormFields,
  category,
  handleLinkChange,
  imageFileUrl,
  pdfFileUrl,
  setPdfFileUrl,
  handleFileChange,
  uploadFile,
  formType,
  formikRef,
  imageFile,
  pdfFile,
  setNewsLinkType,
  newsLinkType,
  errLink,
  errImg,
  errPdf,
  setErrLink,
  resetStates,
  editData,
  setPdfFile,
}) => {
  const formFields = getFormFields(category);

  const validationSchema = Yup.object().shape({
    ...formFields.reduce((acc, field) => {
      acc[field.name] =
        field.fieldType === "date"
          ? Yup.string().required(`${field.label} is required`)
          : Yup.string().trim().required(`${field.label} is required`);
      return acc;
    }, {}),
  });

  return (
    <ThemeContextProvider>
      <div>
        <Formik
          innerRef={formikRef}
          initialValues={formInitialValues()}
          enableReinitialize
          onSubmit={handleFormSubmit}
          validationSchema={validationSchema}
        >
          {({
            values,
            handleChange,
            setFieldValue,
            errors,
            touched,
            isSubmitting,
          }) => {
            return (
              <>
                {isSubmitting && (
                  <Box className="loader-overlay">
                    <CircularProgress size={60} />
                  </Box>
                )}
                <Form>
                  {formFields.map(({ name, fieldType, label }) =>
                    fieldType === "date" || fieldType === "drop" ? null : (
                      <div key={name} className="mb-4 flex flex-col">
                        {fieldType === "textarea" ? (
                          <Field name={name}>
                            {({ field, meta }) => (
                              <>
                                <TextField
                                  {...field}
                                  label={label}
                                  multiline
                                  rows={2}
                                  fullWidth
                                  variant="outlined"
                                  error={meta.touched && Boolean(meta.error)}
                                  helperText={meta.touched && meta.error}
                                />
                              </>
                            )}
                          </Field>
                        ) : (
                          <Field name={name}>
                            {({ field, meta }) => (
                              <>
                                <TextField
                                  {...field}
                                  id={name}
                                  label={label}
                                  fullWidth
                                  variant="outlined"
                                  onChange={handleChange}
                                  error={meta.touched && Boolean(meta.error)}
                                  helperText={meta.touched && meta.error}
                                />
                              </>
                            )}
                          </Field>
                        )}
                      </div>
                    )
                  )}

                  {/* Date and Time Zone */}
                  <Stack direction="row" spacing={2} className="mb-4">
                    {/* Date Field */}
                    <Box flex={1}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Field name="newsDate">
                          {({ field, form, meta }) => (
                            <>
                              <DateTimePicker
                                label="Published Date"
                                value={field.value ? dayjs(field.value) : null} // Ensure it's a valid dayjs object
                                maxDate={dayjs()}
                                timeSteps={{ minutes: 1 }}
                                onChange={(newValue) => {
                                  form.setFieldValue(
                                    "newsDate",
                                    newValue
                                      ? newValue.format("YYYY-MM-DD HH:mm")
                                      : ""
                                  );
                                }}
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    variant: "outlined",
                                    error: meta.touched && Boolean(meta.error), // Show error only if touched
                                    helperText:
                                      meta.touched && meta.error
                                        ? meta.error
                                        : "",
                                    onClick: (e) => {
                                      e.stopPropagation();
                                      e.currentTarget
                                        .querySelector("button")
                                        ?.click(); // Simulates click on calendar icon
                                    },
                                  },
                                }}
                              />
                            </>
                          )}
                        </Field>
                      </LocalizationProvider>
                    </Box>

                    {/* Time Zone Field */}
                    <Box flex={1}>
                      <Field name="timezone">
                        {({ field, form, meta }) => (
                          <Autocomplete
                            options={timeZones}
                            getOptionLabel={(option) => option.label} // Display label in dropdown
                            value={
                              timeZones.find(
                                (tz) => tz.value === field.value
                              ) || null
                            } // Match selected value
                            onChange={(_, newValue) => {
                              form.setFieldValue(
                                "timezone",
                                newValue ? newValue.value : ""
                              ); // Set value in form
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Time Zone"
                                variant="outlined"
                                fullWidth
                                error={meta.touched && Boolean(meta.error)}
                                helperText={meta.touched && meta.error}
                              />
                            )}
                          />
                        )}
                      </Field>
                    </Box>
                  </Stack>

                  {category === MEDIA_CATEGORY.PRESS && (
                    // ||
                    //   category === MEDIA_CATEGORY.ARTICLES
                    <FormControl className="mb-4">
                      <h5 className="mb-1 fw-bold">
                        Select Press Release Link Type
                      </h5>
                      <RadioGroup
                        row
                        value={newsLinkType}
                        onChange={(e) => {
                          if (e.target.value === "pdf") {
                            setPdfFileUrl("");
                          }
                          if (e.target.value === "link") {
                            setPdfFile(null);
                          }
                          setNewsLinkType(e.target.value);
                        }}
                      >
                        <FormControlLabel
                          value="pdf"
                          control={<Radio />}
                          label="PDF"
                        />
                        <FormControlLabel
                          value="link"
                          control={<Radio />}
                          label="Link"
                        />
                      </RadioGroup>
                    </FormControl>
                  )}

                  {newsLinkType === "link" && (
                    <FormControl className="mb-4 w-100" variant="outlined">
                      <>
                        <TextField
                          label={
                            category === "Articles"
                              ? "News Article Link"
                              : "Press Release Link"
                          }
                          value={pdfFileUrl}
                          onChange={(e) => {
                            setPdfFileUrl(e.target.value);
                            if (pdfFileUrl !== "") {
                              setErrLink("");
                            }
                          }}
                          variant="outlined"
                          fullWidth
                        />
                        {errLink && <div className="show-error">{errLink}</div>}
                      </>
                    </FormControl>
                  )}

                  {newsLinkType === "pdf" && (
                    <div className="mb-4 flex flex-col">
                      {/* <hr /> */}
                      <label className="mb-1 font-medium fw-bold pe-3">
                        Upload PDF:
                      </label>
                      <input
                        type="file"
                        accept="application/pdf" // Restrict to PDFs only
                        onChange={(e) => handleFileChange(e, "pdf")}
                      />
                      {errPdf && <div className="show-error">{errPdf}</div>}

                      {formType === FORM_TYPE.EDIT &&
                        pdfFileUrl &&
                        editData?.typeOfLink === "Pdf" && (
                          <div className="upload-pdf-list p-2 mt-3">
                            Uploaded PDF:{" "}
                            <a
                              href={pdfFileUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-500 underline"
                            >
                              View PDF
                            </a>
                          </div>
                        )}
                    </div>
                  )}

                  {(category === MEDIA_CATEGORY.PRESS ||
                    category === MEDIA_CATEGORY.ARTICLES) && (
                    <div className="mb-4 flex flex-col">
                      <label className="mb-1 font-medium fw-bold pe-3">
                        Upload Image:
                      </label>
                      <input
                        type="file"
                        accept="image/png, image/jpeg, image/jpg" // Restrict to PNG, JPEG, JPG
                        onChange={(e) => handleFileChange(e, "image")}
                      />

                      {formType === FORM_TYPE.EDIT && imageFileUrl && (
                        <div className="article-view-img-bg p-2 mt-3 text-center">
                          <img src={imageFileUrl} alt="Uploaded preview" />
                        </div>
                      )}
                    </div>
                  )}
                  {errImg && <div className="show-error">{errImg}</div>}

                  <div className="flex justify-between mt-4">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className="me-3"
                      size="large"
                      disabled={isSubmitting}
                    >
                      {formType === FORM_TYPE.EDIT ? "Update" : "Submit"}
                    </Button>
                    <Button
                      onClick={resetStates}
                      variant="contained"
                      color="secondary"
                      size="large"
                      disabled={isSubmitting}
                    >
                      Close
                    </Button>
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
    </ThemeContextProvider>
  );
};

export default ArticleForm;
