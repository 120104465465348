import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { BootstrapDialog } from "../../utils/styledComponents";
import { ThemeContextProvider } from '../../themecontext';

const DeleteDialog = ({ handleDeleteYes, handleDeleteNo, category, showDeleteDialog, setShowDeleteDialog,selectedStatus,categoryparams }) => {

    return (<>
    <ThemeContextProvider>
        <BootstrapDialog
            onClose={() => { setShowDeleteDialog(false) }}
            aria-labelledby="customized-dialog-title"
            open={showDeleteDialog}
        >
            {/* <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
    {selectedStatus === "A" ? "Delete" : "Activate"} {category}
</DialogTitle> */}
            {/* <IconButton
                aria-label="close"
                onClick={() => { setShowDeleteDialog(false) }}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.grey[500],
                })}
            >
                <CloseIcon />
            </IconButton> */}
            <DialogContent >
            <Typography gutterBottom>
            {selectedStatus === "A" 
  ? `Are you sure you want to delete ${
      category === "Videos"
        ? "the video"
        : categoryparams && categoryparams === "Press"
        ? "the press release"
        : categoryparams && categoryparams === "Articles"
        ? "the news article"
        : "this item"
    } ?`
  : `Are you sure you want to activate ${
      category === "Videos"
        ? "the video"
        : categoryparams && categoryparams === "Press"
        ? "the press release"
        : categoryparams && categoryparams === "Articles"
        ? "the news article"
        : "this item"
    } ?`
}
</Typography>

            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleDeleteNo}
                >No</Button>
                <Button autoFocus
                    onClick={() => handleDeleteYes(selectedStatus === "A" ? "A" : "I")}
                >
                    Yes
                </Button>
            </DialogActions>
        </BootstrapDialog>
        </ThemeContextProvider>
    </>)
}
export default DeleteDialog;