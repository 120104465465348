import React from "react";
import { Box, Toolbar, IconButton, Typography, Button } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar } from "../../../utils/styledComponents";
import { useNavigate } from "react-router-dom";

const Header = ({ open, setOpen }) => {
  const navigate = useNavigate();

  return (
    <AppBar position="fixed" open={open}>
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen(!open)}
            edge="start"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Admin Panel
          </Typography>
        </Box>
        <Button color="inherit" onClick={() => {navigate("/admin/login"); localStorage.removeItem("authToken");}} className="fw-bold">
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
