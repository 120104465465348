import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ThemeContextProvider } from "../../../themecontext";

const VideoDialog = ({ open, handleClose, title, videoLink }) => {
  return (
    <ThemeContextProvider>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontSize: "1.5rem" }}>{title}</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
          <iframe
            width="100%"
            height="400px"
            src={videoLink}
            title={title}
            allowFullScreen
          />
        </DialogContent>
      </Dialog>
    </ThemeContextProvider>
  );
};

export default VideoDialog;
