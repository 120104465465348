import React from "react";
import Home from "./components/home/home";
import "./App.scss";

function App() {
  return (
    <div>
      <Home />
    </div>
  );
}

export default App;
