import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  Button,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Snackbar,
  TableFooter,
  TablePagination,
} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { MdDeleteOutline } from "react-icons/md";
import { MdOutlineModeEdit } from "react-icons/md";
import { TbCopyCheckFilled } from "react-icons/tb";
import { Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import TablePaginationActions from "../paginationActions/TablePaginationActions";

const ArticlesTable = ({
  setShowDeleteDialog,
  setCurrentRowData,
  handleEdit,
  selectedStatus,
  filteredData,
  tableHeaders,
  category,
  tableRows,
  totalCount,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
  tableDataReceived,
}) => {
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    if (filteredData?.length) {
      const updatedData = filteredData.filter((news) => {
        return (
          news?.typeOfNews?.toLowerCase() === category.toLowerCase() ||
          news?.status?.toLowerCase() === selectedStatus.toLowerCase()
        );
      });
      setTableData(updatedData);
    } else {
      setTableData([]);
    }
  }, [category, filteredData, selectedStatus]);

  const [openSnack, setOpenSnack] = useState(false);
  const [content, setContent] = useState("");
  const [severity, setSeverity] = useState("error");

  const renderTooltip = (text, column) => {
    let maxLength = 10;

    switch (column) {
      case "title":
        maxLength = 30;
        break;
      case "newsLink":
        maxLength = 30;
        break;
      case "Description":
        maxLength = 40;
        break;
      default:
        maxLength = 10;
    }

    return (
      <Tooltip title={text} arrow>
        {text.length > maxLength ? `${text.substring(0, maxLength)}...` : text}
      </Tooltip>
    );
  };
  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header, index) => (
                <TableCell key={index}>
                  {header}
                </TableCell>
              ))}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!tableDataReceived && (
              <TableRow>
                <TableCell colSpan={6}>
                  <div className="text-center">
                    <CircularProgress color="inherit" />
                  </div>
                </TableCell>
              </TableRow>
            )}
            {tableData?.length > 0 ? (
              tableData?.map((row) => (
                <TableRow
                  key={row.newsReleaseId}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <>
                    <TableCell  style={{ width: 140 }}>
                      <div className="article-img-bg">
                        <img
                          src={row.imageUrl}
                          alt="News"
                        />
                      </div>
                    </TableCell>
                    <TableCell>
                      {renderTooltip(row.title, "title")}
                    </TableCell>
                    <TableCell>
                      {new Date(row.newsDate).toLocaleDateString()}
                    </TableCell>
                    <TableCell>
                      {renderTooltip(row.newsLink, "newsLink")}
                    </TableCell>
                    <TableCell>
                      {renderTooltip(row.description, "Description")}
                    </TableCell>
                  </>
                  
                  <TableCell>
  {selectedStatus === "A" ? (
    <>
      <Tooltip title="Delete">
        <IconButton
          aria-label="delete"
          onClick={() => {
            setShowDeleteDialog(true);
            setCurrentRowData(row);
          }}
          color="primary"
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Edit">
        <IconButton
          aria-label="edit"
          onClick={() => {
            handleEdit(row);
            setCurrentRowData(row);
          }}
          color="primary"
        >
          <MdOutlineModeEdit />
        </IconButton>
      </Tooltip>
    </>
  ) : (
    <Tooltip title="Activate">
  <span>
    <TbCopyCheckFilled
      onClick={() => {
        setShowDeleteDialog(true);
        setCurrentRowData(row);
      }}
      style={{ fontSize: "1.8em", color: "#C6A566", cursor: "pointer" }}
    />
  </span>
</Tooltip>
  )}
</TableCell>

            
                </TableRow>
              ))
            ) 
            : (tableDataReceived && filteredData.length === 0)? (
              <TableRow>
                <TableCell colSpan={4}>No data available</TableCell>
              </TableRow>
            ) : null}
          </TableBody>
          <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              colSpan={tableHeaders.length + 1}
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
              }}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
              ActionsComponent={TablePaginationActions}
              sx={{
                "& .MuiTablePagination-menuItem": {
                  textAlign: "center",
                },
              }}
            />
          </TableRow>
        </TableFooter>
        </Table>
      </TableContainer>
      <Snackbar
        open={openSnack}
        autoHideDuration={3000}
        onClose={() => setOpenSnack(false)}
        message={content}
        severity={severity}
      />
    </div>
  );
};

export default ArticlesTable;
