import React, { useEffect, useState, useRef } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TextField, Button, Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import logo from "../../assets/GFL-logo.svg";
import { ThemeContextProvider } from "../../themecontext";
import SnackbarUI from "../common/snackbar";
import CircularProgress from "@mui/material/CircularProgress";
import "./adminLogin.scss";
import { api } from "../../utils/api";

const AdminLogin = () => {
  const [open, setOpenSnack] = useState(false);
  const [content, setContent] = useState("");
  const [severity, setSeverity] = useState("success");

  const navigate = useNavigate();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required("Username is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
  });

  const handleLogin = async (values, { setSubmitting, setErrors }) => {
    try {
      const response = await api.post("/users/sign-in", values);

      if (response.status === 201 || response.status === 200) {
        const { token } = response.data.data;
        localStorage.setItem("authToken", token);
        navigate("/admin");
      }
    } catch (error) {
      setOpenSnack(true);
      setContent(error.message);
      setSeverity("error");
      setErrors({ email: "Invalid email or password" });
    }

    setSubmitting(false);
  };

  return (
    <div className="login row g-0 align-items-center justify-content-center">
      <div className="col-md-3 p-3">
        <div className="text-center pb-5">
          <img src={logo} alt="Logo" width={200}></img>
        </div>
        <div className="login-box p-4">
          <ThemeContextProvider>
            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={LoginSchema}
              onSubmit={handleLogin}
            >
              {({
                values,
                errors,
                touched,
                isSubmitting,
                handleChange,
                handleBlur,
              }) => (
                <Form>
                  <Typography
                    variant="h4"
                    textAlign="center"
                    color="primary"
                    className="pb-4"
                  >
                    Login
                  </Typography>

                  <TextField
                    label="Username"
                    name="email"
                    variant="outlined"
                    fullWidth
                    className="mb-4"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />

                  <TextField
                    label="Password"
                    name="password"
                    type="password"
                    variant="outlined"
                    fullWidth
                    className="mb-4"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                  />

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    className="login-btn"
                  >
                    {isSubmitting ? (
                      <CircularProgress color="inherit" />
                    ) : (
                      "Login"
                    )}
                  </Button>
                </Form>
              )}
            </Formik>
            <SnackbarUI
              open={open}
              setOpenSnack={setOpenSnack}
              content={content}
              severity={severity}
            />
          </ThemeContextProvider>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;