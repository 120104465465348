import React, { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Footer from "../footer/footer";
import "./home.scss";
import ContactForm from "../contact-form/contactForm";
import GFLArenaImg1 from "../../assets/gfl-arena-img1.jpg";
import GFLArenaImg2 from "../../assets/gfl-arena-img2.jpg";
import { ThemeContextProvider } from "../../themecontext";
import EventTimer from "../eventTimer/eventTimer";
import { useNavigate } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import { apiEndPoint, headers } from "../../utils/constants";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CloseIcon from "@mui/icons-material/Close";
// import Carousel from "react-multi-carousel";
import Carousel from "react-bootstrap/Carousel";
import "react-multi-carousel/lib/styles.css";
import { Helmet } from "react-helmet-async";
import Junior from "../../assets/fighter/junior.png";
import Andrei from "../../assets/fighter/andrei.png";
import Anthony from "../../assets/fighter/anthony.png";
import Benson from "../../assets/fighter/benson.png";
import Frank from "../../assets/fighter/frank.png";
import Hector from "../../assets/fighter/hector.png";
import Marlon from "../../assets/fighter/marlon.png";
import Tyron from "../../assets/fighter/tyron.png";
import Alexey from "../../assets/fighter/alexey.png";
import Kevin from "../../assets/fighter/kevin.png";
import Alexander from "../../assets/fighter/alexander.png";
import MapImg from "../../assets/map.png";
import videoBeforeMap from "../../assets/map.mp4";
import UsaFlag from "../../assets/usa-flag.png";
import RussiaFlag from "../../assets/russia-flag.png";
import CubaFlag from "../../assets/cuba-flag.png";
import BrazilFlag from "../../assets/brazil-flag.png";
import BelarusFlag from "../../assets/belarus-flag.png";
import SwedenFlag from "../../assets/sweden-flag.png";
import top50 from "../../assets/top50.jpg";
import SnackbarUI from "../common/snackbar";
import { MuiTelInput } from "mui-tel-input";

import dubai from "../../assets/cities/dubai.mp4";
import dubaiThumb from "../../assets/cities/dubai-thumbnail.jpg";
import london from "../../assets/cities/london.mp4";
import londonThumb from "../../assets/cities/london-thumbnail.jpg";
import sao from "../../assets/cities/sao-paulo.mp4";
import saoThumb from "../../assets/cities/sao-thumbnail.jpg";
import ny from "../../assets/cities/newyork.mp4";
import nyThumb from "../../assets/cities/newyork-thumbnail.jpg";
import miami from "../../assets/cities/miami.mp4";
import miamiThumb from "../../assets/cities/miami-thumbnail.jpg";
import la from "../../assets/cities/los-angeles.mp4";
import laThumb from "../../assets/cities/los-thumbnail.jpg";
import draftLogo from "../../assets/draft-logo.png";
import HoverVideoDisplay from "./HoverVideoDisplay";
import CommonCarousel from "../common-carousal/CommonCarousal";

import Banner1 from "../../assets/banners/banner1.jpg";
import Banner2 from "../../assets/banners/banner2.jpg";
import Banner3 from "../../assets/banners/banner3.jpg";
import CarouselCaption from "react-bootstrap/esm/CarouselCaption";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70vw",
  // bgcolor: 'black',
  // borderRadius: '20px',
  backgroundColor: "var(--primary);",
  boxShadow: 24,
  // border: '2px solid #dec372',
  p: 4,
};

const mobileStyle = {
  ...style,
  width: "90vw", // Adjust width for smaller screens
  overflowY: "auto",
  maxHeight: "90vh",
  p: 2, // Adjust padding for mobile view
};

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const initialState = {
  name: "",
  email: "",
  phoneNumber: "",
  nationality: "",
  record: "",
  bio: "",
};

const Nationality = [
  {
    value: "-",
    // label: "Nationality",
    label: "Select Favorite Team",
  },
  {
    value: "LosAngelesAssassins",
    label: "Los Angeles",
  },
  {
    value: "MiamiDiablos",
    label: "Miami",
  },
  {
    value: "NewYorkForce",
    label: "New York",
  },
  // {
  //   value: "MontrealRush",
  //   label: "Montreal",
  // },
  {
    value: "SaoPauloOncas",
    label: "Sao Paulo",
  },
  {
    value: "LondonBullies",
    label: "London",
  },
  // {
  //   value: "ParisMaquis",
  //   label: "Paris",
  // },
  {
    value: "DubaiHunters",
    label: "Dubai",
  },
];
const Home = () => {
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState({});
  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  // const [open, setOpenSnack] = useState(false);
  const [content, setContent] = useState("");
  // const [severity,setSeverity] = useState('success');
  const [videoEnded, setVideoEnded] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);

  const handleVideoStateChange = (id, state) => {
    if (state === "playing") {
      setCurrentlyPlaying(id); // Update the currently playing video ID
    } else if (state === "ended" || state === "paused") {
      if (currentlyPlaying === id) {
        setCurrentlyPlaying(null); // Reset if the currently playing video ends/pauses
      }
    }
  };

  const carouselContents = [
    { src: Banner1, text: "" },
    { src: Banner2, text: "" },
    { src: Banner3, text: "" },
  ];

  const cityVideosData = [
    { id: "london", imageSrc: londonThumb, videoSrc: london },
    { id: "dubai", imageSrc: dubaiThumb, videoSrc: dubai },
    { id: "sao", imageSrc: saoThumb, videoSrc: sao },
    { id: "miami", imageSrc: miamiThumb, videoSrc: miami },
    { id: "la", imageSrc: laThumb, videoSrc: la },
    { id: "ny", imageSrc: nyThumb, videoSrc: ny },
  ];

  const isMobile = window.matchMedia("(max-width: 768px)").matches;
  const responsiveStyle = isMobile ? mobileStyle : style;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const targetDate = new Date("2025-01-15T00:00:00");

  const handleVideoEnd = () => {
    setVideoEnded(true); // Update state when video ends
  };

  const calculateTimeLeft = () => {
    const now = new Date();
    const difference = targetDate - now;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: String(Math.floor(difference / (1000 * 60 * 60 * 24))).padStart(
          2,
          "0"
        ),
        hours: String(
          Math.floor((difference / (1000 * 60 * 60)) % 24)
        ).padStart(2, "0"),
        minutes: String(Math.floor((difference / 1000 / 60) % 60)).padStart(
          2,
          "0"
        ),
        seconds: String(Math.floor((difference / 1000) % 60)).padStart(2, "0"),
      };
    }

    return timeLeft;
  };

  useEffect(() => {
    setTimeLeft(calculateTimeLeft());
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const draftPage = () => {
    // navigate('/draft-application')
    setOpenModal(true);
  };
  const watchPage = () => {
    navigate("/watch");
    // setOpenModal(true);
  };

  const handleForm = useCallback((event) => {
    console.log(event, "eventeventv");

    if (event.target) {
      const { name, value, type, checked } = event.target;
      setFormData((prev) => ({
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      }));
      setErrors((prev) => ({ ...prev, [name]: "" }));
    } else if (event) {
      setFormData((prev) => ({
        ...prev,
        phoneNumber: event,
      }));
      setErrors((prev) => ({ ...prev, phoneNumber: "" }));
    }
  }, []);

  const handleClose = () => setOpenModal(false);

  const saveForm = (e) => {
    e.preventDefault();
    const validateErrors = validateFormData(formData);
  };

  const validateFormData = (data) => {
    let errors = {};
    if (data.name == "") {
      errors.name = "Required";
    }
    if (data.email == "") {
      errors.email = "Required";
    }
    if (data.record == "") {
      errors.record = "Required";
    }
    if (data.bio == "") {
      errors.bio = "Required";
    }
    if (!data.isChecked) {
      errors.isChecked = "Required";
    }
    if (
      data.nationality == "" ||
      data.nationality == "-" ||
      data.nationality == "Select Favourite Team"
    ) {
      errors.nationality = "Required";
    }
    if (data.phoneNumber == "") {
      errors.phoneNumber = "Required";
    }
    return errors;
  };

  const saveDraftForm = (e) => {
    e.preventDefault();
    console.log(formData, "formDataformData");

    const validateErrors = validateFormData(formData);
    setErrors(validateErrors);
    const formdatacopy = { ...formData };

    if ("isChecked" in formdatacopy) {
      delete formdatacopy.isChecked;
    }
    if (Object.keys(validateErrors).length === 0) {
      axios
        .post(`${apiEndPoint}/athlete-draft`, formdatacopy, headers)
        .then((response) => {
          if (response) {
            setFormSubmitted(true);
            // setOpenSnack(true)
          }
        })
        .catch((e) => {
          // setOpenSnack(true)
          setContent("Something Went wrong !");
          // setSeverity("error")
        });
    }
  };
  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      phoneNumber: "",
      nationality: "",
      record: "",
      bio: "",
    });
    setErrors({});
    setFormSubmitted(false);
  };

  const arenaPage = () => {
    navigate("/watch");
  };
  const leaguePage = () => {
    navigate("/league");
  };

  useEffect(() => {
    let player;

    const onYouTubeIframeAPIReady = () => {
      player = new window.YT.Player("videoIframe", {
        events: {
          onStateChange: (event) => {
            if (event.data === window.YT.PlayerState.ENDED) {
              setVideoEnded(true);
            }
          },
        },
      });
    };

    // Load the YouTube IFrame Player API script
    const tag = document.createElement("script");
    tag.src = "https://www.youtube.com/iframe_api";
    document.body.appendChild(tag);

    // Attach API ready function to the global scope
    window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;

    return () => {
      if (player) {
        player.destroy();
      }
    };
  }, []);

  return (
    <div>
      <Helmet>
        <title>GFL MMA League | 2025 Athlete Comebacks & MMA's New Era</title>
        <meta
          property="og:title"
          content="GFL MMA League | 2025 Athlete Comebacks & MMA's New Era"
        />
        <meta
          name="description"
          content="Discover the GFL MMA League, launching in 2025 with top athletes returning to fight. Stay tuned for exclusive athlete updates, event announcements, and streaming details."
        />
        <meta
          name="keywords"
          content="MMA league 2025,GFL MMA league,MMA comeback 2025,mixed martial arts 2025,GFL fight schedule,MMA streaming platform,MMA promotions 2025,MMA fights 2025,top MMA athletes 2025"
        />
      </Helmet>
      {isMobile ? (
        // Mobile version of the carousel
        <Carousel indicators={false}>
          <Carousel.Item>
            <img
              src={require("../../assets/banners/mobile-banner-0.jpg")}
              alt=""
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              src={require("../../assets/banners/mobile-banner-1.jpg")}
              alt="Mobile Banner 2"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              src={require("../../assets/banners/mobile-banner-2.jpg")}
              alt="Mobile Banner 3"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              src={require("../../assets/banners/mobile-banner-3.jpg")}
              alt="Mobile Banner 3"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              src={require("../../assets/banners/mobile-banner-4.jpg")}
              alt="Mobile Banner 3"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              src={require("../../assets/banners/mobile-banner-5.jpg")}
              alt="Mobile Banner 3"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              src={require("../../assets/banners/mobile-banner-6.jpg")}
              alt="Mobile Banner 3"
            />
          </Carousel.Item>
        </Carousel>
      ) : (
        // Web version of the carousel
        <Carousel indicators={false}>
          <Carousel.Item>
            <img
              src={require("../../assets/banners/banner1.jpg")}
              alt="Banner 1"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              src={require("../../assets/banners/banner2.jpg")}
              alt="Banner 2"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              src={require("../../assets/banners/banner3.jpg")}
              alt="Banner 3"
            />
          </Carousel.Item>
        </Carousel>
      )}
      <div className="text-center mt-2 py-2 pre-register">
        <Button variant="primary" onClick={draftPage}>
          Join The Movement{" "}
        </Button>
      </div>

      {/* <section className="home-hero d-flex justify-content-centers pb-5 pb-md-0">
        <div className="container">
          <div className="row gy-5 gx-5 align-items-center">
            <div className="col-md-5 pe-md-5">
              <img
                src={require("../../assets/home-hero-draft.png")}
                className="mw-100"
                style={{ cursor: "pointer" }}
                onClick={watchPage}
              ></img>
            </div>
            <div className="col-md-7 gfl-draft">
              <h1 className="pb-3">
                The GFL Draft 2025 –  The Future of Fighting is Here
              </h1>
              <p className="pb-4">
                Watch LIVE as 120 athletes are selected for the 6 GFL teams. Be
                part of history as the GFL Draft sets the stage for the next era
                of combat sports!
              </p>
              <div className="pb-3">
                <img src={draftLogo} width="120px" />
              </div>
              <div className="d-flex">
                <EventTimer />
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="home-hero2 d-flex justify-content-centers pb-5 pb-md-0">
        <div className="container">
          <div className="row">
            <div className="col-8 col-md-6">
              <h1 className="mb-3">
                Redefining Combat Sports: The Future of Fighting Begins Here
              </h1>
              <p className="pb-3">
                GFL is revolutionizing combat sports with a global, team-based
                league that shares 50% of event revenue with athletes and
                prioritizes their health, wellness, and retirement. This
                groundbreaking league unites fans and athletes, creating MMA’s
                most passionate community.
              </p>
              <Button variant="primary" onClick={draftPage}>
                Join the Movement
              </Button>
            </div>
          </div>
        </div>
      </section>

      <section className="featured-teams py-5">
        <div className="container">
          <div className="row g-0">
            {cityVideosData.map((video) => (
              <div key={video.id} className="col-12 col-md-4">
                <HoverVideoDisplay
                  imageSrc={video.imageSrc}
                  videoSrc={video.videoSrc}
                  isMobile={isMobile}
                  id={video.id}
                  onVideoStateChange={handleVideoStateChange}
                  isPlaying={currentlyPlaying === video.id}
                />
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="introducing py-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <h2 className="text-center primary-color py-3">
                6 Global Arenas, One Epic Stage for Champions
              </h2>
              <div className="text-center sub-text pb-5">
                GFL's elite teams battle it out across 6 iconic cities, 4
                countries and 4 continents
              </div>
            </div>
            <div>
              {videoEnded ? (
                <img
                  src={MapImg}
                  alt="Video ended placeholder"
                  className="mw-100"
                />
              ) : (
                <div className="row justify-content-center">
                  <div className="col-md-6">
                    <video
                      src={videoBeforeMap}
                      autoPlay
                      onEnded={handleVideoEnd}
                      muted
                      // controls
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                // <iframe
                //   id="videoIframe"
                //   width="560"
                //   height="315"
                //   src="https://www.youtube.com/embed/tbe3aL3dFB0?enablejsapi=1&autoplay=1"
                //   title="YouTube video player"
                //   frameBorder="0"
                //   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                //   allowFullScreen
                // ></iframe>
              )}
            </div>
          </div>
        </div>
      </section>

      {/* <div className="map-icons"></div> */}
      {/* <section className="map-view">
        <div className="container">
          <div className="row text-center justify-content-center">
            <div className="col-md-8">
              <p>
                At GFL, we’re transforming combat sports through a fan-first,
                fighter-driven league. Welcome to the most exciting stage for
                athletes and fans alike
              </p>
              <Button variant="primary" onClick={leaguePage}>
                Learn More
              </Button>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className="py-5 fighter-list">
        <div className="container"> */}
      {/* <h2 className="text-center primary-color pb-4">
          The Top 50 Athletes Are Draft Eligible – The Future of Combat Sports Starts Here          </h2>
          <div className="text-center pb-5 sub-text">
          Meet the most followed and highly anticipated athletes eligible for the GFL Draft. The stage is set for a new era in combat sports—who will be the first pick?

          </div> */}
      {/* <Carousel
            responsive={responsive}
            autoPlay={true}
            autoPlaySpeed={2000}
            keyBoardControl={true}
            // customTransition="all .5"
            // transitionDuration={500}
            infinite={true}
          >
            <div className="fighter-box">
              <figure>
                <img width="100%" src={Anthony} />
              </figure>
              <div className="fighter-details text-center">
                <div className="figher-name">Anthony Pettis</div>
                <div>Record: 24-12</div>
                <div>Nickname: “Showtime”</div>
                <div>
                  USA <img src={UsaFlag} width={"30px"}></img>
                </div>
                <div>
                  Instagram:{" "}
                  <a
                    href="https://www.instagram.com/showtimepettis/?hl=en"
                    target="_blank"
                  >
                    @showtimepettis
                  </a>
                </div>
              </div>
            </div>
            <div className="fighter-box">
              <figure>
                <img width="100%" src={Tyron} />
              </figure>
              <div className="fighter-details text-center">
                <div className="figher-name">Tyron Woodley</div>
                <div>Record: 19-7</div>
                <div>Nickname: “The Chosen One”</div>
                <div>
                  USA <img src={UsaFlag} width={"30px"}></img>
                </div>
                <div>
                  Instagram:{" "}
                  <a
                    href="https://www.instagram.com/therealest/"
                    target="_blank"
                  >
                    @therealest
                  </a>
                </div>
              </div>
            </div>
            <div className="fighter-box">
              <figure>
                <img width="100%" src={Alexey} />
              </figure>
              <div className="fighter-details text-center">
                <div className="figher-name">Alexey Oleynik</div>
                <div>Record: 61-18</div>
                <div>Nickname: “The Boa Constrictor”</div>
                <div>
                  Russia <img src={RussiaFlag} width={"30px"}></img>
                </div>
                <div>
                  Instagram:{" "}
                  <a
                    href="https://www.instagram.com/alexeyoleynik1/"
                    target="_blank"
                  >
                    @alexeyoleynik1
                  </a>
                </div>
              </div>
            </div>
            <div className="fighter-box">
              <figure>
                <img width="100%" src={Hector} />
              </figure>
              <div className="fighter-details text-center">
                <div className="figher-name">Hector Lombard</div>
                <div>Record: 24-10</div>
                <div>Nickname: "Showeather"</div>
                <div>
                  CUBA <img src={CubaFlag} width={"30px"}></img>
                </div>
                <div>
                  Instagram:{" "}
                  <a
                    href="https://www.instagram.com/hectorlombard/"
                    target="_blank"
                  >
                    @hectorlombard
                  </a>
                </div>
              </div>
            </div>
            <div className="fighter-box">
              <figure>
                <img width="100%" src={Junior} />
              </figure>
              <div className="fighter-details text-center">
                <div className="figher-name">Junior Dos Santos</div>
                <div>Record: 21-9</div>
                <div>Nickname: “Cigano”</div>
                <div>
                  Brazil <img src={BrazilFlag} width={"30px"}></img>
                </div>
                <div>
                  Instagram:{" "}
                  <a
                    href="https://www.instagram.com/juniorcigano/"
                    target="_blank"
                  >
                    @juniorcigano
                  </a>
                </div>
              </div>
            </div>
            <div className="fighter-box">
              <figure>
                <img width="100%" src={Andrei} />
              </figure>
              <div className="fighter-details text-center">
                <div className="figher-name">Andrei Arlovski</div>
                <div>Record: 33-20</div>
                <div>Nickname: “The Pitbull”</div>
                <div>
                  Belarus <img src={BelarusFlag} width={"30px"}></img>
                </div>
                <div>
                  Instagram:{" "}
                  <a
                    href="https://www.instagram.com/andreiarlovski/"
                    target="_blank"
                  >
                    @andreiarlovski
                  </a>
                </div>
              </div>
            </div>
            <div className="fighter-box">
              <figure>
                <img width="100%" src={Marlon} />
              </figure>
              <div className="fighter-details text-center">
                <div className="figher-name">Marlon Moraes</div>
                <div>Record: 23-13</div>
                <div>Nickname: “Magic”</div>
                <div>
                  Brazil <img src={BrazilFlag} width={"30px"}></img>
                </div>
                <div>
                  Instagram:{" "}
                  <a
                    href="https://www.instagram.com/mmarlonmoraes/"
                    target="_blank"
                  >
                    @mmarlonmoraes
                  </a>
                </div>
              </div>
            </div>
            <div className="fighter-box">
              <figure>
                <img width="100%" src={Frank} />
              </figure>
              <div className="fighter-details text-center">
                <div className="figher-name">Frank Mir</div>
                <div>Record: 19-13</div>
                <div>Nickname:"The Answer"</div>
                <div>
                  USA <img src={UsaFlag} width={"30px"}></img>
                </div>
                <div>
                  Instagram:{" "}
                  <a
                    href="https://www.instagram.com/thefrankmir/"
                    target="_blank"
                  >
                    @thefrankmir
                  </a>
                </div>
              </div>
            </div>
            <div className="fighter-box">
              <figure>
                <img width="100%" src={Benson} />
              </figure>
              <div className="fighter-details text-center">
                <div className="figher-name">Benson Henderson</div>
                <div>Record: 24-7</div>
                <div>Nickname: "Smooth"</div>
                <div>
                  USA <img src={UsaFlag} width={"30px"}></img>
                </div>
                <div>
                  Instagram:{" "}
                  <a
                    href="https://www.instagram.com/bensonhenderson/"
                    target="_blank"
                  >
                    @bensonhenderson
                  </a>
                </div>
              </div>
            </div>
            <div className="fighter-box">
              <figure>
                <img width="100%" src={Kevin} />
              </figure>
              <div className="fighter-details text-center">
                <div className="figher-name">Kevin Lee</div>
                <div>Record: 19-8</div>
                <div>Nickname: "The Motown Phenom"</div>
                <div>
                  USA <img src={UsaFlag} width={"30px"}></img>
                </div>
                <div>
                  Instagram:{" "}
                  <a
                    href="https://www.instagram.com/motownphenom/"
                    target="_blank"
                  >
                    @motownphenom
                  </a>
                </div>
              </div>
            </div>
            <div className="fighter-box">
              <figure>
                <img width="100%" src={Alexander} />
              </figure>
              <div className="fighter-details text-center">
                <div className="figher-name">Alexander Gustafsson</div>
                <div>Record: 18-8</div>
                <div>Nickname: "The Mauler"</div>
                <div>
                  Sweden <img src={SwedenFlag} width={"30px"}></img>
                </div>
                <div>
                  Instagram:{" "}
                  <a
                    href="https://www.instagram.com/alexthemauler/"
                    target="_blank"
                  >
                    @alexthemauler
                  </a>
                </div>
              </div>
            </div>
          </Carousel> */}
      {/* <img src={top50} alt="top50" className="mw-100"/>
          <div className="text-center pt-3">
              <Button variant="primary"  onClick={() => navigate("/athletes")}>
              Athletes
              </Button>
          </div> */}
      {/* <div className="row justify-content-center pt-5">
            <div className="col-md-8 text-center pt-3">
              <h3>
                Plus, over 250 fighters registered for the Draft. <br></br>
                GFL unites legends and rising stars worldwide.
              </h3>
            </div>
          </div> */}
      {/* </div>
      </section> */}

      {/* <section className="introducing py-0 py-md-5">
        <div className="container">
          <div className="row align-items-center pb-0 pb-md-5">
            <div className="col-auto">
              <h2 className="primary-color m-0 text-nowrap">HOW TO WATCH</h2>
            </div>
            <div className="col">
              <div className="border-top"></div>
            </div>
            <div className="col-auto text-end">
              <Button variant="outline-primary" onClick={arenaPage}>
                Enter the GFL APP
              </Button>
            </div>
          </div>
          <h3 className="py-5 text-center">
            GFL APP: Your All-Access Home to Watch, Follow, and Experience Every
            GFL Fight—<br></br>Live, Anywhere, on Any Device.
          </h3>
          <div className="row">
            <div className="col-md-6">
              <div className="section-box position-relative">
                <img src={GFLArenaImg1} className="mw-100"></img>
                <div className="section-title">
                  Step into the Future of Combat Sports Viewing with the GFL
                  APP.
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="section-box position-relative">
                <img src={GFLArenaImg2} className="mw-100"></img>
                <div className="section-title">
                  Enjoy real-time fighter stats, multiple camera views, live fan
                  chat, and exclusive content.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className="py-5">
        <ThemeContextProvider>
          <div className="draft-application-form">
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div className="col-md-8 gfl-draft text-center">
                  <h2 className="pb-3">
                    The GFL Draft 2025 – Your Path to Greatness
                  </h2>
                  <p className="pb-4">
                    Witness the future of combat sports unfold as top athletes
                    vie for their moment of glory. The GFL Draft is where
                    careers are forged and greatness begins!
                  </p>
                  <div className="text-center pb-3">
                    <img src={draftLogo} width="200px" />
                  </div>
                  <EventTimer />
                  <div className="row justify-content-center">
                        <div className="col-md-9 text-center">
                         
                          <div>
                            <Button variant="primary" className="me-3" onClick={draftPage}>
                              Athlete Draft Submission
                            </Button>
                            <Button variant="outline-primary">Learn More</Button>
                          </div>
                        </div>
                      </div>
                </div>
                <div className="col-md-4 pt-5 pt-md-0">
                  <div className="contact-box p-4">
                    {!formSubmitted ? (
                      <form>
                        <div className="pb-3">
                          <FormControl className="w-100" variant="outlined">
                            <OutlinedInput
                              id="outlined-adornment-weight"
                              aria-describedby="outlined-weight-helper-text"
                              inputProps={{
                                "aria-label": "weight",
                              }}
                              name="name"
                              placeholder="Name*"
                              value={formData.name}
                              onChange={handleForm}
                            />
                          </FormControl>

                          {errors && errors.name && (
                            <p className="error mb-0 text-start">
                              {errors.name}
                            </p>
                          )}
                        </div>
                        <div className="pb-3">
                          <FormControl className="w-100" variant="outlined">
                            <OutlinedInput
                              id="outlined-adornment-weight"
                              aria-describedby="outlined-weight-helper-text"
                              inputProps={{
                                "aria-label": "weight",
                              }}
                              name="email"
                              placeholder="Email*"
                              value={formData.email}
                              onChange={handleForm}
                            />
                          </FormControl>
                          {errors && errors.email && (
                            <p className="error mb-0 text-start">
                              {errors.email}
                            </p>
                          )}
                        </div>
                        <div className="pb-3">
                            <FormControl className="w-100" variant="outlined">
                              <OutlinedInput
                                id="outlined-adornment-weight"
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                  "aria-label": "weight",
                                  inputMode: "numeric",
                                  pattern: "[0-9]*",
                                }}
                                name="phoneNumber"
                                placeholder="Phone Number*"
                                value={formData.phoneNumber}
                                onChange={handleForm}
                              />
                            </FormControl>
                            {errors && errors.phoneNumber && (
                              <p className="error mb-0 text-start">{errors.phoneNumber}</p>
                            )}
                          </div>

                        <div className="pb-3">
                          <FormControl className="w-100" variant="outlined">
                            <MuiTelInput
                              defaultCountry="US"
                              value={formData.phoneNumber}
                              onChange={handleForm}
                              placeholder="Phone Number*"
                              name="phoneNumber"
                              error={!!errors.phoneNumber} // Show error outline if there's an error
                            />
                          </FormControl>
                          {errors && errors.phoneNumber && (
                            <p className="error mb-0 text-start">
                              {errors.phoneNumber}
                            </p>
                          )}
                        </div>

                        <div className="pb-3">
                          <TextField
                            id="outlined-select-currency"
                            select
                            defaultValue="-"
                            // placeholder="Nationality"
                            placeholder="Select Favorite City"
                            onChange={handleForm}
                            name="nationality"
                            className="w-100"
                          >
                            {Nationality.map((option) => (
                              <MenuItem
                                key={option.value}
                                value={option.value}
                                defaultValue="Okay"
                              >
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                          {errors && errors.nationality && (
                            <p className="error mb-0 text-start">
                              {errors.nationality}
                            </p>
                          )}
                        </div>
                        <div className="pb-3">
                          <FormControl className="w-100" variant="outlined">
                            <OutlinedInput
                              id="outlined-adornment-weight"
                              aria-describedby="outlined-weight-helper-text"
                              inputProps={{
                                "aria-label": "weight",
                              }}
                              name="record"
                              placeholder="Record*"
                              value={formData.record}
                              onChange={handleForm}
                            />
                          </FormControl>
                          {errors && errors.record && (
                            <p className="error mb-0 text-start">
                              {errors.record}
                            </p>
                          )}
                        </div>
                        <div className="pb-3">
                          <TextField
                            id="outlined-multiline-static"
                            multiline
                            rows={4}
                            placeholder="Bio*"
                            defaultValue="Athlete Bio"
                            className="w-100"
                            name="bio"
                            value={formData.bio}
                            onChange={handleForm}
                          />
                          {errors && errors.bio && (
                            <p className="error mb-0 text-start">
                              {errors.bio}
                            </p>
                          )}
                        </div>
                        <div>
                          <div className="d-flex align-items-center pb-3">
                            <Checkbox
                              type="checkbox"
                              name="isChecked"
                              checked={formData.isChecked}
                              onChange={(e) => handleForm(e)}
                            />
                            <label for="checkbox">
                              {" "}
                              Agree with{" "}
                              <a href="/terms-of-service" target="_blank">
                                Terms
                              </a>{" "}
                              and{" "}
                              <a href="/cookie-policy" target="_blank">
                                Privacy policy
                              </a>
                            </label>
                          </div>
                          {errors.isChecked && (
                            <p
                              className="error mb-0 text-start ps-2"
                              style={{ position: "relative", top: "-20px" }}
                            >
                              {errors.isChecked}
                            </p>
                          )}
                        </div>
                        <Button
                          variant="primary"
                          type="submit"
                          className="w-100 rounded-pill"
                          onClick={saveDraftForm}
                        >
                          Submit
                        </Button>
                        {content.length!=0 && content}
                      </form>
                    ) : (
                      <div className="thank-you-message text-center">
                        <h3 className="text-white">
                        Thanks for joining the GFL community. Stay tuned for updates. 
                        </h3>
                        <Button
                          variant="primary"
                          className="mt-3 px-5"
                          onClick={resetForm}
                        >
                          Ok
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ThemeContextProvider>
      </section> */}

      {/* <section className="featured-teams pb-5">
        <div className="container">
          <h2 className="primary-color pb-3">Season 1 Cities</h2>
          <div className="row g-0">
            <div className="col-6 col-md-4">
              <div
                className="team position-relative d-flex justify-content-center align-items-center"
                style={{
                  backgroundImage: `url(${require("../../assets/los-angeles-assassins.jpg")})`,
                }}
              >
                <div className="text-center z-1">
                  <div className="title">Los Angeles</div>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-4">
              <div
                className="team position-relative d-flex justify-content-center align-items-center"
                style={{
                  backgroundImage: `url(${require("../../assets/new-york-force.jpg")})`,
                }}
              >
                <div className="text-center z-1">
                  <div className="title">New York</div>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-4">
              <div
                className="team position-relative d-flex justify-content-center align-items-center"
                style={{
                  backgroundImage: `url(${require("../../assets/miami-diablos.jpg")})`,
                }}
              >
                <div className="text-center z-1">
                  <div className="title">Miami</div>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-4">
              <div
                className="team position-relative d-flex justify-content-center align-items-center"
                style={{
                  backgroundImage: `url(${require("../../assets/sao-paulo-oncas.jpg")})`,
                }}
              >
                <div className="text-center z-1">
                  <div className="title">São Paulo</div>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-4">
              <div
                className="team position-relative d-flex justify-content-center align-items-center"
                style={{
                  backgroundImage: `url(${require("../../assets/london-bullies.jpg")})`,
                }}
              >
                <div className="text-center z-1">
                  <div className="title">London</div>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-4">
              <div
                className="team position-relative d-flex justify-content-center align-items-center"
                style={{
                  backgroundImage: `url(${require("../../assets/dubai-hunters.jpg")})`,
                }}
              >
                <div className="text-center z-1">
                  <div className="title">Dubai</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openModal}>
          <Box sx={responsiveStyle}>
            <CloseIcon onClick={handleClose} className="modal-close" />
            <ThemeContextProvider>
              <ContactForm openModal={openModal} />
            </ThemeContextProvider>
          </Box>
        </Fade>
      </Modal>

      <ThemeContextProvider>
        <ContactForm openModal={openModal} />
      </ThemeContextProvider>
      <Footer />
      {/* <SnackbarUI
        open={open}
        setOpenSnack={setOpenSnack}
        content={content}
        severity={severity}
      /> */}
    </div>
  );
};

export default Home;
